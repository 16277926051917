import { useEffect } from 'react'

import './Pages.css'
import PageElement from './PageElement'

import Loader from '../Loader/Loader'

const Pages = ({ pages, insertPageAfter, updatePageData, removePage, paperOptions, tabOptions, defaultPapers, loading }) => {
  useEffect(() => {
  }, [pages])

  const groupedPages = () => {
    const pageGroups = []
    for(let i = 0; i< pages.length; i++){
      if(pages[i].frontAndBack){
        const group = [[pages[i], i]]
        if(i+1 < pages.length){
          group.push([pages[i+1], i+1])
        }
        pageGroups.push(group)
        i++
      } else {
        pageGroups.push([[pages[i], i]])
      }
    }

    return pageGroups
  }

  const setFrontAndBack = (pages, checked) => {
    const is_checked = checked ? 1 : 0;
    const index = pages[0][1]
    const page = pages[0][0]
    console.log(page)
    console.log(paperOptions)
    // this should probably look up a two sided version of the same paper so color and weight
    const currentPaper = paperOptions.find(paper => paper.id == page.paperOption);
    
    let twoSidedPaper = twoSidedMatch(currentPaper); 
    let oneSidedPaper = oneSidedMatch(currentPaper); 

    let defaultPaper = defaultPapers.find(paper => paper.frontAndBack === is_checked)

    if(!twoSidedPaper){
      twoSidedPaper = defaultPaper
    }

    if(!oneSidedPaper){
      oneSidedPaper = defaultPaper
    }

    if(!checked){
      updatePageData(index, { paperOption: oneSidedPaper.id, frontAndBack: checked })
      if(pages.length > 1){
        // updatePageData(pages[1][1], { paperOption: defaultPaper })
      }
    }else {
      updatePageData(index, { frontAndBack: checked, paperOption: twoSidedPaper.id })
    }
    document.getElementById('whole-doc-front-and-back').checked = false
  }

  const twoSidedMatch = (original) => {
    if (original.meta.print_color[0].includes('Two')){
      return original
    }

    return paperOptions.find( paper => { 
      return paper.meta.print_color[0].includes('Two') &&
              paper.meta.weight[0] === original.meta.weight[0] &&
              paper.meta.color[0] === original.meta.color[0] &&
              paper.meta.type[0] === original.meta.type[0]
    })
  }

  const oneSidedMatch = (original) => {
    if (original.meta.print_color[0].includes('One')){
      return original
    }

    return paperOptions.find( paper => { 
      return (paper.meta.print_color[0].includes('One') || paper.meta.print_color[0].includes('Front Only')) &&
              paper.meta.weight[0] === original.meta.weight[0] &&
              paper.meta.color[0] === original.meta.color[0] &&
              paper.meta.type[0] === original.meta.type[0]
    })
  }

  const defaultColor = (page, pageId) => {
    if(page && page.pageType === 'tab'){
      return tabOptions[0]
    }

    if(page && page.pageType === 'cover'){
      const defaultPaper = defaultPapers.find(dp => dp.frontAndBack === page.frontAndBack && dp.pageType === 'cover')
      return paperOptions.find(paper => paper.id === parseInt(defaultPaper.id))
    }

    if(page && page.paperOption){
      return paperOptions.find(paper => paper.id === parseInt(page.paperOption))
    }

    const defaultPaper = defaultPapers.find(dp => dp.frontAndBack === page.frontAndBack)
    updatePageData(pageId, { paperOption: defaultPaper.id })
    return paperOptions.find(paper => paper.id === parseInt(defaultPaper.id))
  }

  const filterPaperOptions = (pageType) => {
    if(pageType === 'cover'){
      return paperOptions.filter(paper => paper.meta.weight[0] === 'Cover Weight' || paper.meta.weight[0] === 'Medium Weight')
    }

    if(pageType === 'tab'){
      return tabOptions
    }

    return paperOptions
  }
  
  if(loading){
    return(
      <div id='PDF-Content' className='Pdf-Editor-Content'>
        <Loader />
      </div>
    )
  }

  return (
    <div id='PDF-Content' className='Pdf-Editor-Content'>
      { pages.length > 0 ? groupedPages().map((gpages, i) => {
        const defColor = defaultColor(gpages[0][0], gpages[0][1])
        const filteredPaperOptions = filterPaperOptions(gpages[0][0].pageType)
        return(
          <PageElement 
            key={i} 
            pages={gpages} 
            insertPageAfter={() => insertPageAfter(i, 'page')} 
            insertTabAfter={() => insertPageAfter(i, 'tab')} 
            updatePage={(data) => updatePageData(gpages[0][1], data)} 
            setFrontAndBack={setFrontAndBack}
            removePage={removePage}
            paperOptions={filteredPaperOptions}
            defaultColor={defColor}
            paperType={gpages[0][0].pageType}
            tabOptions={tabOptions}
          />
        )
        }) : '' }
    </div>
  )
}

export default Pages