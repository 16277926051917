
import './Loader.css'

const Loader = () => {
  return(
    <div className='pdf-loader-holder'>
      <div class='pdf-loader' >
        <div class='pdf-loader-logo'>
          <img src='https://aeacreativeservices.org/wp-content/uploads/2020/05/iowa-aea-logo-300.png' alt='' />
        </div>
        <div class='pdf-loader-animated'>
          <div>Loading</div>
          <div class='pdf-loader-dot'></div>
          <div class='pdf-loader-dot'></div>
          <div class='pdf-loader-dot'></div>
        </div>
      </div>
    </div>
  )
}

export default Loader