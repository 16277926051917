import { useEffect } from 'react'
import SelectComponent from '../FormComponents/SelectComponent'

import './EditorMenu.css'

const EditorMenu = ({ 
  files, 
  pages, 
  loadPdf, 
  toggleFrontCover, 
  toggleBackCover, 
  setDocToFrontAndBack, 
  calcCost, 
  saveAndDownloadPDF,
  paperSizes,
  paperOptions,
  setPaperSize,
  pdfOptions,
  finishingOptions,
  updatePdfOptions,
  cancelPdf,
  loading
}) => {

  const getOptions = (type) => {
    const options = [{ value: '', label: 'None' }]
    finishingOptions.filter(opt => {
      return opt.meta.type[0] === type
    }).map(opt => opt.meta.option[0])
    .filter((opt, index, array) => {
      return array.indexOf(opt) === index
    })
    .forEach(opt => {
      options.push({ value: opt, label: opt })
    })
    return options
  }

  useEffect(() => {
    if(!loading){
      document.getElementById('copy-size').focus()
    }
  }, [files, loading])

  const setBindingOptions = (data) => {
    updatePdfOptions({ bindingOptions: { ...pdfOptions.bindingOptions,  ...data } })
  }

  return (
    <div className='Pdf-Editor-Menu'>
      { files.length > 0 ? (<>
        {files.map(file =>
          <p key={file.name}> <strong>{file.name} </strong></p>
        )}
      </>) : ''}
      <hr />
      <p>Page Count: {pages.length}</p>
      <p>
        <SelectComponent 
          id='copy-size'
          label='Copy Size' 
          options={paperSizes}
          classList={['select-option']}
          onChangeHandler={setPaperSize}
          value={pdfOptions.paperSize}
        />
      </p>
      <p><label>Front Cover <input type='checkbox' name='frontCover' value='true' onChange={(e) => toggleFrontCover(e.target.checked)} /></label></p>
      <p><label>Back Cover <input type='checkbox' name='backCover' value='true' onChange={(e) => toggleBackCover(e.target.checked)} /></label></p>
      <p><label>Whole Document Front and Back <input id='whole-doc-front-and-back' type='checkbox' name='two-sided' onChange={setDocToFrontAndBack} /></label></p>
      <hr />
      <div>
        <p>
          <SelectComponent 
            id={'staple'}
            label={'Stapled'}
            classList={['select-option']}
            options={getOptions('Staple')}
            onChangeHandler={(e) => { setBindingOptions({ staple: e.target.value }) }}
            value={pdfOptions.bindingOptions.staple}
          />
        </p>
        <p>
          <SelectComponent 
            id={'binding'}
            label={'Binding'}
            classList={['select-option']}
            options={[
              { value: '', label: 'None' },
              { value: 'Coil Top', label: 'Coil Top' },
              { value: 'Coil Left', label: 'Coil Left' },
            ]}
            onChangeHandler={(e) => { setBindingOptions({ binding: e.target.value })}}
            value={pdfOptions.bindingOptions.binding}
          />
        </p>
        <p>
          <SelectComponent 
            id={'folding'}
            label={'Folding'}
            classList={['select-option']}
            options={getOptions('Fold')}
            onChangeHandler={(e) => { setBindingOptions({ folding: e.target.value })}}
            value={pdfOptions.bindingOptions.bfolding}
          />
        </p>
        <p>
          <SelectComponent 
            id={'cutting'}
            label={'Cutting'}
            classList={['select-option']}
            options={getOptions('Cut')}
            onChangeHandler={(e) => { setBindingOptions({ cutting: e.target.value })}}
            value={pdfOptions.bindingOptions.cutting}
          />
        </p>
        <p>
          <SelectComponent 
            id={'drilling'}
            label={'Drilling'}
            classList={['select-option']}
            options={getOptions('Drill')}
            onChangeHandler={(e) => { setBindingOptions({ drilling: e.target.value })}}
            value={pdfOptions.bindingOptions.drilling}
          />
        </p>
        <p>
          <SelectComponent 
            id={'padding'}
            label={'Padding'}
            classList={['select-option']}
            options={getOptions('Padding')}
            onChangeHandler={(e) => { setBindingOptions({ padding: e.target.value })}}
            value={pdfOptions.bindingOptions.padding}
          />
        </p>
      </div>
      <div>
        <p>
          <label> Collate <input name='collate' type='checkbox' checked={pdfOptions.bindingOptions.collate} onChange={(e) => setBindingOptions({ collate: e.target.checked }) }/></label>
        </p>
      </div>
      <div>
        <p><label>Needing Graphics <input type='checkbox' checked={pdfOptions.needsGraphics} onChange={(e) => updatePdfOptions({ needsGraphics: e.target.checked })}/></label></p>
        { pdfOptions.needsGraphics && <p>
          <label htmlFor='graphics-desc'>Please Describe any Graphics work you need completed for this document.</label><br />
          <textarea id='graphics-desc' value={pdfOptions.graphicsDescription} onChange={(e) => updatePdfOptions({ graphicsDescription: e.target.value })}></textarea>
        </p> }
      </div>
      <p><label>Number of Copies <input type='number' min='1' value={pdfOptions.numberOfCopies} className='input' onChange={(e) => updatePdfOptions({ numberOfCopies: e.target.value })} /></label></p>
      <p>Total Cost: {calcCost()}</p>
      <p><button onClick={saveAndDownloadPDF} class='single_add_to_cart_button button alt simple-add-btn'>Save Options and Upload</button></p>
      <p><button onClick={cancelPdf} class='button'>Cancel</button></p>
    </div>
  )
}

export default EditorMenu