import { useState, useEffect } from 'react'
import { PDFDocument,  PageSizes } from 'pdf-lib'

import './App.css';
import Pages from './components/Pages/Pages';
import EditorMenu from './components/EditorMenu/EditorMenu'

// pdf.js not pdf-lib
const pdfjs = window.pdfjsLib

const isDev = window.location.hostname === 'localhost' ? true : false;

function App() {
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [pages, setPages] = useState([])
  const [pdfOptions, setPdfOptions] = useState(
    { 
      addFrontCover: false, 
      addBackCover: false, 
      coverOption: null, 
      bindingOptions: {
        staple: '',
        binding: '',
        folding: '',
        cutting: '',
        drilling: '',
        padding: '',
        collate: false
      }, 
      frontAndBack: false,
      paperSize: '8.5X11',
      defaultPapers: window.defaultPapers,
      needsGraphics: false,
      graphicsDescription: '',
      numberOfCopies: 1 
    }
  )

  useEffect(() => {
    const rootRoute = isDev ? 'http://127.0.0.1:8001' : ''

    Promise.all([
      fetch(rootRoute + '/?rest_route=/printoptions/v1/paper_options')
        .then(data => data.json())
        .then(json => {
          setPaperOptions(json)
        }),
    
      fetch(rootRoute + '/?rest_route=/printoptions/v1/finish_options')
        .then(data => data.json())
        .then(json => {
          setFinishOptions(json)
        }) ])
    .then(() => {
      if(window.cartItem){
        const updatedPdfOptions = {...pdfOptions}
        updatedPdfOptions.numberOfCopies = parseInt(window.cartItem.quantity)
        setPdfOptions(updatedPdfOptions)

        Promise.all(
          window.cartItem.artwork_links.map(async link => {
            let response = await fetch(link)
            let data = await response.arrayBuffer()

            return data
          })
        ).then(data => {
          Promise.all(
            data.map(async file => {
              const newPages = await convertFile(file)
              return newPages
            })
          ).then(collectedPages => {
            let pages = []
            collectedPages.forEach(pageSet => {
              pages = [...pages, ...pageSet]
            })
            setPages((prevPages) => [...prevPages, ...pages])
          })
        })

        const file_with_names = window.cartItem.artworkfiles.split(';').map(file => { 
          let fileSplit = file.split('/')
          let fileName = fileSplit[fileSplit.length - 1]
          return { name: fileName }
        })

        setFiles(file_with_names)
      }

    })

  }, [])

  const [paperOptions, setPaperOptions] = useState([])
  const [finishOptions, setFinishOptions] = useState([])

  const loadPdf = async (e) => {
    setLoading(true)
    setFiles(Array.from(e.target.files))
    Array.from(e.target.files)
         .sort((a, b) => { 
           if(a.name.toLowerCase() < b.name.toLowerCase()){ return -1 }
           if(a.name.toLowerCase() > b.name.toLowerCase()){ return 1 }
           return 0
          })
         .forEach(selectedFile => {
            if(selectedFile.type === 'application/pdf'){
              const reader = new FileReader()
              reader.readAsArrayBuffer(selectedFile)
              reader.onload = async () => {
                const file = reader.result
                const newPages = await convertFile(file)
                setPages((prevPages) => [...prevPages, ...newPages])
                setLoading(false)
              }
            } else {
              console.log(selectedFile.name, 'Not a pdf')
              setLoading(false)
            }
          })
  }

  const convertFile = async (file) => {
    const pdfDoc = await PDFDocument.load(file)
    const pdfPageData = await getPageUris(pdfDoc, 'page', file);
    return pdfPageData
  }

  const getPageUris = async (pdfDoc, pageType, file) => {
    return await Promise.all(pdfDoc.getPages().map( async (page, i) => {
      return await pageData(pdfDoc, page, i, pageType, file)
    }))
  }

  const pageData = async (pdf, page, i, pageType, file) => {
    const pagePdf = await PDFDocument.create()
    const pagesCopied = await pagePdf.copyPages(pdf, [i])
    const pageCopy = pagesCopied[0]
    pagePdf.addPage(pageCopy)
    const pdfDataUri = await pagePdf.saveAsBase64({ dataUri: true })

    // create canvas image
    const loadedPdf = await pdfjs.getDocument(file).promise
    const pdfPage = await loadedPdf.getPage(i + 1)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const viewport = pdfPage.getViewport({scale: 0.35, rotation: 0})

    canvas.width = viewport.width;
    canvas.height = viewport.height;

    canvas.style.padding = '2px 5px'

    let image = ''
    const taskRender = pdfPage.render({
      canvasContext: ctx,
      viewport: viewport
    })
    image = canvas.toDataURL('image/png')

    await taskRender.promise

    image = canvas.toDataURL('image/png') //ctx.getImageData(0,0,canvas.width, canvas.height)

    return { 
      pagePdf: pagePdf, 
      uri: pdfDataUri, 
      paperOption: pdfOptions.defaultPapers.find(dp => !dp.frontAndBack ).id, 
      laminated: false, 
      frontAndBack: false,
      pageType,
      tabText: '',
      perforated: false,
      score: false,
      imageUri: image,
      height: viewport.height,
      width: viewport.width
    }
  }

  const calcCost = () => {
    let total = 0.00
    // gather paper prices
    if(pages.length > 0){
      for(let i = 0; i < pages.length; i++){
        const page = pages[i]
        let pageCost = 0.00
        if(page.paperOption){
          const paper = paperOptions.find(po => parseInt(po.id) === parseInt(page.paperOption))
          let paperValue = parseFloat(paper.meta.price[0].replace('$', ''))

          if(page.pageType === 'tab'){
            paperValue = parseFloat(window.tabCost)
          }

          pageCost += paperValue
        
          if(page.laminated){
            // calc lamination
            let lenWidth = [(8.5 / 12), (11 / 12)]
            if(paper.meta.size[0].toLowerCase().includes('x')){
              lenWidth = paper.meta.size[0].toLowerCase().split('x').map(function(len){
                return parseFloat(len) / 12
              })
            }
            
            const laminationCost = lenWidth[0] * lenWidth[1] * parseFloat(window.lamination_fee)
            pageCost = (pageCost + laminationCost).toFixed(2)
          }
        }

        if(page.frontAndBack){
          i++
        }

        total = total + parseFloat(pageCost)
      }
    }
     
    var finishing = pdfOptions.bindingOptions
    // check binding options and total costs of available binding
    if(finishing.binding){
      const fittedBinding = findBindingOption()
      const fittedCost = parseFloat(fittedBinding.meta.price[0]) + 0.01 * pages.length
      total += fittedCost
    }

    if(finishing.staple){
      const option = findFinishingOption('Staple', finishing.staple)
      total += parseFloat(option.meta.price[0])
    }

    if(finishing.folding){
      const option = findFinishingOption('Fold', finishing.folding)
      if(option){
        const pageTotalCost = parseFloat(option.meta.price[0]) * pages.length
        total += pageTotalCost
      }
    }

    if(finishing.cutting){
      const option = findFinishingOption('Cut', finishing.cutting)
      if(option){
        const pageTotalCost = parseFloat(option.meta.price[0]) * pages.length
        total += pageTotalCost
      }
    }

    if(finishing.drilling){
      const option = findFinishingOption('Drill', finishing.drilling)
      if(option){
        const pageTotalCost = parseFloat(option.meta.price[0]) * pages.length
        total += pageTotalCost
      }
    }

    if(finishing.padding){
      const option = findFinishingOption('Padding', finishing.padding)
      if(option){
        const pageTotalCost = parseFloat(option.meta.price[0]) * pages.length
        total += pageTotalCost
      }
    }

    if(finishing.collate){
      const option = findFinishingOption('Collate', 'Collate')
      if(option){
        const pageTotalCost = parseFloat(option.meta.price[0]) * pages.length
        total += pageTotalCost
      }
    }

    const computedTotal = Math.round(total * pdfOptions.numberOfCopies * 100) / 100

    return `$${computedTotal.toFixed(2)}`
  }

  const findFinishingOption = (type, value) => {
    return finishOptions.find(option => option.meta.type[0] === type && option.meta.option[0] === value)
  }

  const findBindingOption = () => {
    const pageCount = pages.length
    // return exact binding option
    console.log(finishOptions.filter(option => option.meta.type[0] === 'Bind'))
    return finishOptions.filter(option => option.meta.type[0] === 'Bind' && parseInt(option.meta.max_page_count[0]) >= pageCount)
                        .sort((a, b) => parseInt(a.meta.max_page_count[0]) - parseInt(b.meta.max_page_count[0]))[0]
  }

  const insertPageAfter = async (i, pageType) => {
    const blankPageData = await createBlankPage(pageType)
    if(pageType === 'tab'){
      blankPageData.paperOption = tabOptions()[0].id
    }
    if(pageType === 'cover'){
      blankPageData.paperOption = transformedDefaultPaper().filter(dp => dp.pageType === 'cover')[0].id
    }
    const updatedPages = [...pages]
    updatedPages.splice(i + 1, 0, blankPageData)
    setPages(updatedPages)
  }

  const insertPageBefore = async (i, pageType) => {
    const blankPageData = await createBlankPage(pageType)
    if(pageType === 'cover'){
      blankPageData.paperOption = transformedDefaultPaper().filter(dp => dp.pageType === 'cover')[0].id
    }
    const updatedPages = [...pages]
    updatedPages.splice(i, 0, blankPageData)
    setPages(updatedPages)
  }

  const createBlankPage = async (pageType) => {
    const pagePdf = await PDFDocument.create()
    pagePdf.addPage([PageSizes.A4[0], PageSizes.A4[1]]);
    const file = await pagePdf.save()
    const blankPages = await getPageUris(pagePdf, pageType, file)
    return blankPages[0]
  }

  const saveAndDownloadPDF = async () => {
    const pagePdf = await PDFDocument.create() 
    await Promise.all(pages.map( async page => {
      const pagesCopied = await pagePdf.copyPages(page.pagePdf, [0])
      const pageCopy = pagesCopied[0]
      pagePdf.addPage(pageCopy)
    }))

    const pdfBytes = await pagePdf.save()

    const fileName = files.map(file => 
      file.name
          .replace('.pdf', '')
          .replace(/\s/g, '-')
          .replace(/[.,/#!$%^&*;:{}=`'"~()]/g,"")
    ).join('_') + '.pdf'
    
    upload(pdfBytes, fileName, 'application/pdf')
    // download(pdfBytes, fileName, 'application/pdf')
  }

  const upload = (file, filename, type) => {
    const rootRoute = isDev ? 'http://127.0.0.3:8080' : ''

    let binaryData = []
    binaryData.push(file)
    const newfile = new Blob(binaryData, {type: type})
    // const newfile = new File([binaryData], filename, { type: type })

    // console.log(newfile, newfile.type)

    var data = new FormData()
    data.append('created_file', newfile, filename)

    fetch(rootRoute + '/?rest_route=/printoptions/v1/upload_file', {
      method: 'POST',
      body: data
    }).then(response => response.json())
      .then(success => handleSubmit(success))
      .catch(err => console.log(err))

    // handleSubmit({ file_url: 'https://google.com/myimage' })
  }

  function handleSubmit(artwork){
    const artworkLink = artwork.file_url

    const form = document.createElement('form')
    form.action = window.formAction
    form.method = 'post'

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const modify = urlParams.get('modify')

    // if modify
    if(modify){
      // echo "<input type='hidden' name='cart_item_key' value=" . esc_attr( $_GET['modify'] ) . " />";
      const cartItemKey = document.createElement('input')
      cartItemKey.type = 'hidden'
      cartItemKey.value = modify
      cartItemKey.name = 'cart_item_key'
      form.appendChild(cartItemKey)

      // echo "<input type='hidden' name='cpp_update_process_action' value='update-cart' />";
      const updateProcessAction = document.createElement('input')
      updateProcessAction.type = 'hidden'
      updateProcessAction.value = 'update-cart'
      updateProcessAction.name = 'cpp_update_process_action'
      form.appendChild(updateProcessAction)

    } else {

      const productIdInput = document.createElement('input')
      productIdInput.type = 'hidden'
      productIdInput.value = window.productID
      productIdInput.name = 'add-to-cart'
      form.appendChild(productIdInput)
    }

    const quantity = document.createElement('input')
    quantity.type = 'hidden'
    quantity.value = pdfOptions.numberOfCopies
    quantity.name = 'quantity'
    form.appendChild(quantity)

    const artworkInput = document.createElement('input')
    artworkInput.type = 'hidden'
    artworkInput.value = artworkLink
    artworkInput.name = 'artworkfiles'
    form.appendChild(artworkInput)

    // output paper options
    const paperOptions = generatePageOptions()

    paperOptions.forEach((opt, i) => {
      // create hidden fields for each thing
      // paper color print_option[0][paper_color]
      const paperColorInput = document.createElement('input')
      paperColorInput.type = 'hidden'
      paperColorInput.name = `print_option[${i}][paper_color]`
      paperColorInput.value = opt.paperId
      // page count print_option[0][page_count]
      const pageCountInput = document.createElement('input')
      pageCountInput.type = 'hidden'
      pageCountInput.name = `print_option[${i}][page_count]`
      pageCountInput.value = opt.pageCount
      // pages listed print_option[0][which_pages]
      const wichPageInput = document.createElement('input')
      wichPageInput.type = 'hidden'
      wichPageInput.name = `print_option[${i}][which_pages]`
      wichPageInput.value = convertPagesToSpans(opt.pages)

      // page laminated print_option[0][laminated]
      const laminatedInput = document.createElement('input')
      laminatedInput.type = 'hidden'
      laminatedInput.name = `print_option[${i}][laminated]`
      laminatedInput.value = opt.laminated ? 'yes' : 'no'

      // tab text
      const tabTextInput = document.createElement('input')
      tabTextInput.type = 'hidden'
      tabTextInput.name = `print_option[${i}][tab_text]`
      tabTextInput.value = opt.tabText 

      // perforation
      const perforatedInput = document.createElement('input')
      perforatedInput.type = 'hidden'
      perforatedInput.name = `print_option[${i}][perforate]`
      perforatedInput.value = opt.perforated 

      // score
      const scoreInput = document.createElement('input')
      scoreInput.type = 'hidden'
      scoreInput.name = `print_option[${i}][score]`
      scoreInput.value = opt.score 

      
      form.appendChild(paperColorInput)
      form.appendChild(pageCountInput)
      form.appendChild(wichPageInput)
      form.appendChild(laminatedInput)
      form.appendChild(tabTextInput)
    })

    // output finish options

    // Check pdf options for which binding is selected
    const bindingOptions = pdfOptions.bindingOptions

    if(bindingOptions.binding) {
      const bindingInput = document.createElement('input')
      bindingInput.type = 'hidden'
      bindingInput.name = `bind`
      bindingInput.value = bindingOptions.binding
      form.appendChild(bindingInput)
    }
    if(bindingOptions.staple) {
      const stapleInput = document.createElement('input')
      stapleInput.type = 'hidden'
      stapleInput.name = `staple`
      stapleInput.value = bindingOptions.staple
      form.appendChild(stapleInput)
    }
    if(bindingOptions.folding) {
      const foldInput = document.createElement('input')
      foldInput.type = 'hidden'
      foldInput.name = `fold`
      foldInput.value = bindingOptions.folding
      form.appendChild(foldInput)
    }
    if(bindingOptions.drilling) {
      const drillInput = document.createElement('input')
      drillInput.type = 'hidden'
      drillInput.name = `drill`
      drillInput.value = bindingOptions.drilling
      form.appendChild(drillInput)
    }
    if(bindingOptions.padding) {
      const padInput = document.createElement('input')
      padInput.type = 'hidden'
      padInput.name = `pad`
      padInput.value = bindingOptions.padding
      form.appendChild(padInput)
    }
    if(bindingOptions.cutting) {
      const cutInput = document.createElement('input')
      cutInput.type = 'hidden'
      cutInput.name = `cut`
      cutInput.value = bindingOptions.cutting
      form.appendChild(cutInput)
    }

    if(bindingOptions.collate) {
      const collateInput = document.createElement('input')
      collateInput.type = 'hidden'
      collateInput.name = `collate`
      collateInput.value = bindingOptions.collate
      form.appendChild(collateInput)
    }

    document.body.appendChild(form)

    form.submit()
  }

  const generatePageOptions = () => {
    if(pages.length === 0){ return [] }
    
    const pageOptions = []
    for(let i = 0; i < pages.length; i++){
      const page = pages[i]
      const paper = paperOptions.find(paper => paper.id === parseInt(page.paperOption))
      const pageNumber = i + 1
      
      let pageOption = { paperId: paper.id, pages: [], pageCount: 0, laminated: page.laminated }
      
      const pageOptionIndex = pageOptions.findIndex(po => po.paperId === paper.id && po.laminated === page.laminated)
      
      if(pageOptionIndex >= 0){
        pageOption = {...pageOptions[pageOptionIndex]}
      }

      pageOption.pages = convertPagesToList(pageOption.pages)

      pageOption.pages.push(pageNumber)
      pageOption.pageCount += 1

      if(page.frontAndBack){
        pageOption.pages.push(pageNumber + 1)
        // { paperId, paperTitle, [Array]pages, pageCount }
        i++
      }

      if(pageOptionIndex >= 0){
        pageOptions[pageOptionIndex] = pageOption
      }else {
        pageOptions.push(pageOption)
      }
    }
    return pageOptions
  }

  const convertPagesToSpans = (input) => {
		let pages = input.map(item => parseInt(item)).sort((a, b) => a - b)

    let pageStart = pages[0]
    let pageEnd = pages[0]
    let pageRanges = []
    for(var i = 0; i < pages.length; i++) {
      let page = pages[i]
      
      if (page === pageEnd + 1) {
      	pageEnd = page
        if(i+1 === pages.length){
        	pageRanges.push([pageStart, pageEnd].join('-'))
        }
      } else if(page === pageEnd + 1 ) {
      	pageEnd = page
      } else if (page > pageEnd + 1) {
        if(pageStart === pageEnd) {
       	  pageRanges.push(pageEnd)
        } else {
      	  pageRanges.push([pageStart, pageEnd].join('-'))
        }
        pageStart = page
        pageEnd = page
        if(i+1 === pages.length) {
          pageRanges.push(page)
        }
      } else if (i+1 === pages.length) { 
      	if(pageStart === pageEnd) {
       	  pageRanges.push(pageEnd)
        } else {
      	  pageRanges.push([pageStart, pageEnd].join('-'))
        }
      }
    }
    return pageRanges.join(", ")
  }

  const convertPagesToList = (pageRanges) => {
    let pageList = []
    pageRanges.forEach(page => {
      if(typeof page === 'string' && page.includes('-')){
        let pageRange = page.split('-')
        for(let i = pageRange[0]; i <= pageRange[1]; i++){
          pageList.push(i)
        }
      }else {
        pageList.push(page)
      }
    })
    return pageList
  }

  const download = (file, filename, type) => {
    let binaryData = []
    binaryData.push(file)
    const data = URL.createObjectURL(new Blob(binaryData, {type: type}))

    const link = document.createElement('a');
    link.href = data;
    link.download = filename;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', { 
        bubbles: true, 
        cancelable: true, 
        view: window 
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  const updatePageData = (i, data) => {
    const updatedPages = [...pages]
    updatedPages.splice(i, 1, {...pages[i], ...data})
    setPages(updatedPages)
  }

  const setDocToFrontAndBack = (e) => {
    const updatedPages = [...pages]
    for(let i = 0; i < pages.length; i += 2){
      if(!(i === pages.length - 1)){
        const defaultPaper = transformedDefaultPaper().find(dp => dp.frontAndBack === e.target.checked).id
        const data = { frontAndBack: e.target.checked, paperOption: defaultPaper }
        updatedPages.splice(i, 1, {...pages[i], ...data})
      }
    }
    setPages(updatedPages)
  }

  const removePage = (index) => {
    const updatedPages = [...pages]
    updatedPages.splice(index, 1)
    setPages(updatedPages)
  }

  const toggleFrontCover = (checked) => {
    setPdfOptions((prevOptions => ({...prevOptions, addFrontCover: checked})))
    if(checked){
      insertPageBefore(0, 'cover')
    } else {
      removePage(0)
    }
  }

  const toggleBackCover = (checked) => {
    setPdfOptions((prevOptions => ({...prevOptions, addBackCover: checked})))
    if(checked){
      insertPageAfter(pages.length - 1, 'cover')
    } else {
      removePage(pages.length - 1)
    }
  }

  const sortedPaperOptions = () => {
    return paperOptions.filter(paper => {
      return paper.meta.size[0] === pdfOptions.paperSize
    })
  }

  const tabOptions = () => {
    // return paperOptions.filter(paper => {
    //   return paper.meta.type[0].includes('Tab')
    // })
    return [{ id: window.tabUsed, meta: { type: [''], weight: [''], size: [''], print_color: [''], color: ['tab']  } }]
  }

  const paperSizes = () => {
    const sizes = []
    paperOptions.forEach(paper => {
      if(!sizes.includes(paper.meta.size[0])){
        sizes.push(paper.meta.size[0])
      }
    })
    
    return sizes.filter(size => size.toLowerCase().includes('x'))
                .sort((a, b) => parseFloat(a.toLowerCase().split('x')[0]) - parseFloat(b.toLowerCase().split('x')[0]) )
                .map(size => {
                  return { value: size, label: size }
                })
  }

  const setPaperSize = (e) => {
    const paperSize = e.target.value
    const defaultPapers = convertedDefaultPaper().filter( paper => paper.size === paperSize )

    const updatedPages = pages.map(page => {
      const defaultPaper = defaultPapers.find(dp => {
        const curPagePaperColor = paperOptions.find(p => p.id == page.paperOption).meta.print_color[0] 

        return dp.frontAndBack === page.frontAndBack && dp.printColor === curPagePaperColor
      }).id
      return {...page, paperOption: defaultPaper}
    })
    setPdfOptions({...pdfOptions, paperSize, defaultPaper: defaultPapers[0].id })
    setPages(updatedPages)
  }

  const adminStyle = () => {
    const style = { display: 'grid', gridTemplateColumns: '', gridGap: '10px', justifyContent: 'center', height: '100%'}
    style.gridTemplateColumns = '1fr'
    return style
  }

  const updatePdfOption = (data) => {
    const updatedPdfOptions = {...pdfOptions, ...data}
    setPdfOptions(updatedPdfOptions)
  }

  const clearPageData = () => {
    setFiles([])
    setPages([])
    document.getElementById('pdf-input').value = ''
  }

  const convertedDefaultPaper = () => {
    return pdfOptions.defaultPapers.map(dp => {
      const paper = paperOptions.find(paper => paper.id === dp.id)
      const printColor = paper.meta.print_color[0]

      const twoSided = dp.frontAndBack ? true : false;
      return {...dp, frontAndBack: twoSided, printColor}
    })
  }

  const transformedDefaultPaper = () => {
    return convertedDefaultPaper().filter( paper => paper.size === pdfOptions.paperSize )
  }

  return (
    <div style={adminStyle()} aria-live="polite" aria-busy={loading}>

      <label>Pdf File: <input type='file' onChange={loadPdf} accept="application/pdf" id='pdf-input'/></label>
      {files.length > 0 || pages.length > 0 ? (
        <div className="App">
          {/* <div className='Background' onClick={clearPageData} ></div> */}
          <div className='Pdf-Editor'>
            <EditorMenu 
              files={files} 
              pages={pages} 
              loading={loading}
              loadPdf={loadPdf} 
              toggleFrontCover={toggleFrontCover}
              toggleBackCover={toggleBackCover}
              setDocToFrontAndBack={setDocToFrontAndBack}
              calcCost={calcCost}
              saveAndDownloadPDF={saveAndDownloadPDF}
              paperOptions={sortedPaperOptions()}
              paperSizes={paperSizes()}
              finishingOptions={finishOptions}
              setDefaultPaperOption={(option)=>{}}
              setPaperSize={setPaperSize}
              pdfOptions={pdfOptions}
              updatePdfOptions={updatePdfOption}
              cancelPdf={clearPageData}
            />
            <Pages 
              pages={pages}
              pdfOptions={pdfOptions}
              insertPageAfter={insertPageAfter} 
              updatePageData={updatePageData}
              removePage={removePage}
              createBlankPage={createBlankPage}
              paperOptions={sortedPaperOptions()}
              defaultPapers={transformedDefaultPaper()}
              tabOptions={tabOptions()}
              loading={loading}
            />
          </div>
          
        </div>
      ) : '' }
    </div>
  );
}

export default App;
