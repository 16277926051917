const SelectComponent = ({ id, label, options, value, classList, onChangeHandler }) => (
  <>
    <label htmlFor={id}>{label}:</label>
    <select className={classList.join(' ')} id={id} onChange={onChangeHandler} value={value}>
      {options.map(selectOption => 
        <option 
          key={selectOption.value} 
          value={selectOption.value} 
        >
          {selectOption.label}
        </option>
      )}
    </select>
  </>
)

export default SelectComponent