import { createRef, useEffect, useState } from 'react'

const CanvasElement = ({ page, children }) => {
  const canvasRef = createRef();

  const renderPage = (canvas) => {
    if(canvas) {
      const ctx = canvas.getContext('2d')
      canvas.width = page.width
      canvas.height = page.height

      canvas.style.padding = '2px 5px'

      ctx.putImageData(page.imageUri, 0, page.height)
    }
  }

  // useEffect(() => {
  //   renderPage(canvasRef.current)
  // }, [page.imageUri])

  // return (
  //   <div className='page-view-box'>
  //     <canvas ref={canvasRef}></canvas>
  //     {children}
  //   </div>
  // )
  return <img src={page.imageUri} alt='' />
}

const PageElement = ({ pages, paperType, updatePage, insertPageAfter, insertTabAfter, setFrontAndBack, removePage, paperOptions, defaultColor, tabOptions }) => {
  const [paperFilters, setPaperFilters] = useState({ printColor: defaultColor.meta.print_color[0], paperWeight: defaultColor.meta.weight[0], paperType: defaultColor.meta.type[0] })
  const createCanvasElement = (page, index) => {
    return <div className='page-view-box'>
      <CanvasElement page={page} key={index} />
      { paperType !== 'cover' && 
        <button type='button' title={`Remove Page ${index + 1}`} name='Remove Page' className='remove' aria-label='Remove Page' onClick={() => removePage(index)}>X</button>
      }
    </div>
  }

  useEffect(() => {
    if (defaultColor) {
      setPaperFilters({ 
        printColor: defaultColor.meta.print_color[0], 
        paperWeight: defaultColor.meta.weight[0], 
        paperType: defaultColor.meta.type[0] 
      })
    }
  }, [defaultColor])

  const printColors = () => {
    const colors = []

    if(pages[0][0].frontAndBack){
      paperOptions.forEach(paper => {
        if(!colors.includes(paper.meta.print_color[0]) && paper.meta.print_color[0].includes('Two')){
          colors.push(paper.meta.print_color[0])
        }
      })
    }else {
      paperOptions.forEach(paper => {
        if(!colors.includes(paper.meta.print_color[0]) && (paper.meta.print_color[0].includes('Front Only') || paper.meta.print_color[0].includes('One'))){
          colors.push(paper.meta.print_color[0])
        }
      })
    }
    
    return colors.sort()
  }

  const paperWeights = () => {
    const weights = []
    paperOptions.filter(paper => {
      return paper.meta.print_color[0] === paperFilters.printColor
    }).forEach(paper => {
      if(!weights.includes(paper.meta.weight[0]))
      weights.push(paper.meta.weight[0])
    })

    return weights
  }

  const paperTypes = () => {
    if(paperFilters.paperWeight === ''){
      return ['Select a Paper Weight']
    }
    const types = []

    paperOptions.filter(paper => {
      return paper.meta.print_color[0] === paperFilters.printColor 
              && paper.meta.weight[0] === paperFilters.paperWeight
    }).forEach(paper => {
      if(!types.includes(paper.meta.type[0])){
        types.push(paper.meta.type[0])  
      }
    })

    return types
  }

  const paperSizes = () => {
    if(paperFilters.paperType === ''){
      return ['Select a Paper Type']
    }
    const sizes = []

    paperOptions.filter(paper => {
      return paper.meta.print_color[0] === paperFilters.printColor 
              && paper.meta.weight[0] === paperFilters.paperWeight
              && paper.meta.type[0] === paperFilters.paperType
    }).forEach(paper => {
      if(!sizes.includes(paper.meta.size[0])){
        sizes.push(paper.meta.size[0])  
      }
    })

    return sizes

  }

  const paperColors = () => {
    if(paperFilters.paperWeight === ''){
      return [{ id: '', color:'Select a Paper Weight'}]
    }

    if(paperFilters.paperType === ''){
      return [{ id: '', color: 'Select a Paper Type'}]
    }

    return paperOptions.filter(paper => {
      return paper.meta.print_color[0] === paperFilters.printColor 
              && paper.meta.weight[0] === paperFilters.paperWeight
              && paper.meta.type[0] === paperFilters.paperType
        
    }).map(paper => { return { id: paper.id, color: paper.meta.color[0] } })
  }

  const addTab = (e) => {
    if(e.target.checked){
      updatePage({ pageType: 'tab', paperOption: tabOptions[0].id })
    } else {
      updatePage({ pageType: 'page', paperOption: null })
    }
  }

  return (
    <div className='page'>
      <div className='page-view-options'>
        <div className='page-view-holder'>
          {pages.map(([page, index], i) => {
            return createCanvasElement(page, index)
          })}
        </div>
        <div className='page-options'>
        <h2>{ paperType === 'tab' ? '(Tab)' : ''} { paperType === 'cover' ? '(Cover)' : '' } Page { pages.length > 1 ? `s ${pages[0][1] + 1} - ${pages[1][1] + 1}` : ` ${pages[0][1] + 1}`} Options</h2>
          <div><label>Front and Back? <input type='checkbox' name='frontAndBack' value='true' checked={pages[0][0].frontAndBack} onChange={(e)=> setFrontAndBack(pages, e.target.checked)} /></label></div>
          <div><label>Laminated? <input type='checkbox' value='true' onChange={(e) => updatePage({ laminated: e.target.checked })} /></label></div>
          {/* <div><label>Perforate? <input type='checkbox' onChange={(e) => updatePage({ perforated: e.target.checked })} /></label></div>
          <div><label>Score? <input type='checkbox' onChange={(e) => updatePage({ score: e.target.checked })} /></label></div> */}
          <div><label>Add Tab <input type='checkbox' onChange={(e) => addTab(e)} checked={paperType === 'tab'}/></label></div>
          {paperType === 'tab' ? (
            <div>
              <label htmlFor={`tab-text-${pages[0][1]}`}>Tab Text</label><br />
              <input type='text' id={`tab-text-${pages[0][1]}`} value={pages[0][0].tabText} onChange={(e) => updatePage({ tabText: e.target.value })} />
            </div>
          ) : ''}
          {paperType === 'tab' ? '' : (
            <div>
              <h3>Paper</h3>
              <div>
                <label>Print Color</label><br />
                <select onChange={(e) => setPaperFilters({ printColor: e.target.value, paperWeight: '', paperType: '' })} value={paperFilters.printColor}>
                  <option value=''>Select a Printing Color</option>
                  {printColors().map(color => 
                    <option key={color} value={color} >
                      {color}
                    </option>
                  )}
                </select>
              </div>
              <div>
                <label>Paper Weight</label><br />
                <select onChange={(e) => setPaperFilters({ ...paperFilters, paperWeight: e.target.value, paperType: '' })} value={paperFilters.paperWeight}>
                  <option value=''>Select a Paper Weight</option>
                  {paperWeights().map(weight => 
                    <option key={weight} value={weight}>{weight}</option>
                  )}
                </select>
              </div>
              <div>
                <label>Paper Type</label><br />
                <select onChange={(e) => setPaperFilters({ ...paperFilters, paperType: e.target.value })} value={paperFilters.paperType}>
                  {paperTypes()[0] !== 'Select a Paper Weight' ? <option value=''>Select a Paper Type</option> : ''}
                  {paperTypes().map(type => 
                    <option key={type} value={type}>{type}</option>
                  )}
                </select>
              </div>
              {paperType === 'tab' ? (
                <div>
                  <label>Tab Size</label><br />
                  <select onChange={(e) => setPaperFilters({ ...paperFilters, paperSize: e.target.value })} value={paperFilters.paperSize}>
                    {paperSizes()[0] !== 'Select a Paper Type' ? <option value=''>Select a Paper Type</option> : ''}
                    {paperSizes().map(type => 
                      <option key={type} value={type}>{type}</option>
                    )}
                  </select>
                </div>
              ) : ""}
              <div>
                <label>Paper Color</label><br />
                <select onChange={e => updatePage({ paperOption: e.target.value })} value={pages[0][0].paperOption}>
                  {paperFilters.paperType !== '' ? <option value=''>Select a Paper Color</option>: ''}
                  {paperColors().map(({id, color}) => 
                    <option key={id} value={id}>{color}</option>
                  )}
                </select>
              </div>
            </div>
          )}
          <br />
          <button className='addPage single_add_to_cart_button button alt simple-add-btn' style={{marginRight: '10px'}} type='button' name='Add Page' onClick={insertPageAfter}>Add Blank Page After Page { `${pages[pages.length - 1][1] + 1}` }</button>
          <button className='addPage single_add_to_cart_button button alt simple-add-btn' type='button' name='Add Page' onClick={insertTabAfter}>Insert Tab Page After Page { `${pages[pages.length - 1][1] + 1}` }</button>
        </div>
      </div>
    </div>
  )
}
  
export default PageElement